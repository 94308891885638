import { ref } from "vue";
const toastMessages = ref([]);
export function makeToast(msg, color) {
  let obj;
  if (typeof msg === "string") {
    if (!color) {
      color = "primary";
    }
    obj = {
      content: msg,
      color,
    };
  } else {
    obj = msg;
  }
  toastMessages.value.push(obj);
}

export function useToast() {
  return { toastMessages };
}
