<template>
  <div
    v-if="error && error.statusCode === 404"
    class="wrapper min-vh-100 d-flex flex-row align-items-center"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="6">
          <div class="clearfix">
            <h1 class="float-start display-3 me-4">404</h1>
            <h4 class="pt-3">{{ $t("error.title.page_not_found") }}</h4>
            <p class="text-body-secondary float-start">
              {{ $t("error.message.page_not_found") }}
            </p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
  <div
    v-else-if="error"
    class="wrapper min-vh-100 d-flex flex-row align-items-center"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="6">
          <div class="clearfix">
            <h1 class="float-start display-3 me-4">{{ error.statusCode }}</h1>
            <h4 class="pt-3">{{ $t("error.title.internal_server_error") }}</h4>
            <p class="text-body-secondary float-start">
              {{ $t("error.message.internal_server_error") }}<br />
              <span v-if="error.data && error.data.message">{{
                error.data.message
              }}</span>
              <span v-else>{{ error }}</span>
            </p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
  <div v-else class="wrapper min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="6">
          <div class="clearfix">
            <h3 class="display-3 me-4">
              {{ $t("error.message.network") }}
            </h3>
            <br />
            <h4 class="pt-3">{{ $t("error.title.internal_server_error") }}</h4>
            <p class="text-body-secondary float-start">
              {{ $t("error.message.internal_server_error") }}
            </p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import {
  CCol,
  CContainer,
  CRow,
} from "@coreui/vue/dist/cjs/components/grid/index.js";

const error = useError();
if (error && error.value && error.value.statusCode === 404) {
  const router = useRouter();
  const route = useRoute();
  const routes = router.getRoutes();
  let found = false;
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].path === route.path) {
      found = true;
    }
  }
  if (found) {
    navigateTo(route.fullPath);
  } else {
    const { t } = useI18n();
    window.onerror({
      reason: {
        message: t("result.message.not_found", { url: route.path }),
      },
    });
  }
}
</script>
