<template>
  <Html :data-coreui-theme="theme" test="tr"> </Html>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <Toast />
</template>

<script setup lang="ts">
import { useNuxtApp } from "#app";
import * as icons from "@coreui/icons";
import { useHead } from "@unhead/vue";
import { useI18n } from "vue-i18n";
import CoreuiVue, { useColorModes } from "@coreui/vue";
import { onBeforeMount } from "vue";

import { useThemeStore } from "@/stores/theme.js";
import { useUserFetch } from "~/composables/userFetch";
import { useUserStore } from "~/stores/user";
import { useAuth } from "~/composables/useAuth";

const myNuxtApp = useNuxtApp();
myNuxtApp.vueApp.use(CoreuiVue);
myNuxtApp.vueApp.provide("icons", icons);
const { t } = useI18n();
useHead({
  link: [
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/webframework/img/favicon.ico", // 원하는 favicon 경로
    },
  ],
  title: t("common.title.website"),
});

const { isColorModeSet, setColorMode } = useColorModes(
  "coreui-free-vue-admin-template-theme",
);
const currentTheme = useThemeStore();

const { isAuthenticated } = useAuth();
if (isAuthenticated()) {
  const { data } = await useUserFetch();
  const user = data.value ? data.value.data : null;
  const userStore = useUserStore();
  userStore.setUser(user);
}
let theme: any = "dark";
onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  theme = urlParams.get("theme");

  if (theme !== null && theme.match(/^[A-Za-z0-9\s]+/)) {
    theme = theme.match(/^[A-Za-z0-9\s]+/)[0];
  }

  if (theme) {
    setColorMode(theme);
    return;
  }

  if (isColorModeSet()) {
    return;
  }

  setColorMode(currentTheme.theme);
});
</script>
