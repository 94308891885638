import { default as _404iq8i20h38SMeta } from "/var/jenkins_home/workspace/q-framework-admin-frontend/pages/404.vue?macro=true";
import { default as _500NnWKnhePN0Meta } from "/var/jenkins_home/workspace/q-framework-admin-frontend/pages/500.vue?macro=true";
import { default as chartFDncjtPvYJMeta } from "/var/jenkins_home/workspace/q-framework-admin-frontend/pages/chart.vue?macro=true";
import { default as errorYyq4ojXUcMMeta } from "/var/jenkins_home/workspace/q-framework-admin-frontend/pages/error.vue?macro=true";
import { default as index6GoTlC5XrUMeta } from "/var/jenkins_home/workspace/q-framework-admin-frontend/pages/index.vue?macro=true";
import { default as logingd9Ru8xnnyMeta } from "/var/jenkins_home/workspace/q-framework-admin-frontend/pages/login.vue?macro=true";
import { default as registrationekWf1z5JV6Meta } from "/var/jenkins_home/workspace/q-framework-admin-frontend/pages/registration.vue?macro=true";
import { default as testrbx49yyAiyMeta } from "/var/jenkins_home/workspace/q-framework-admin-frontend/pages/test.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _404iq8i20h38SMeta || {},
    component: () => import("/var/jenkins_home/workspace/q-framework-admin-frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    meta: _500NnWKnhePN0Meta || {},
    component: () => import("/var/jenkins_home/workspace/q-framework-admin-frontend/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "chart",
    path: "/chart",
    component: () => import("/var/jenkins_home/workspace/q-framework-admin-frontend/pages/chart.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    meta: errorYyq4ojXUcMMeta || {},
    component: () => import("/var/jenkins_home/workspace/q-framework-admin-frontend/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index6GoTlC5XrUMeta || {},
    component: () => import("/var/jenkins_home/workspace/q-framework-admin-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logingd9Ru8xnnyMeta || {},
    component: () => import("/var/jenkins_home/workspace/q-framework-admin-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    meta: registrationekWf1z5JV6Meta || {},
    component: () => import("/var/jenkins_home/workspace/q-framework-admin-frontend/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/var/jenkins_home/workspace/q-framework-admin-frontend/pages/test.vue").then(m => m.default || m)
  }
]